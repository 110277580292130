export const API_VERSION = "v1.0";
let HOST_URL = window.location.host
let backend_url = ''
if (HOST_URL =='petro.brij.tech') {
  backend_url = 'https://api-petro.brij.tech'
}
else if (HOST_URL == 'petro.brij.tech') {
  backend_url =  'https://api-petro.brij.tech'
}
else {
  backend_url = 'localhost'
}
export const BASEURL_BRIJ = backend_url
const DEVBASEURL = `http://localhost:3800`;
export const BASEURL = backend_url;
export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYwN2VkY2MwLTNjODQtNGRhZC1hY2VmLTBjN2FhYjNmMzU1MyIsImlhdCI6MTYwNjg5MzA0MCwiZXhwIjoxNjA5NDg1MDQwfQ.3dkWIqaDYXjJ0Eayq_nL8Q0rbmqfYcyI3NLZbcbKh8Y`;
export const PETRO_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjgwMjQxYjUwLTI5NDEtNDVkOC04ZWZhLWM1MzExZDMyMTBkYSIsImlhdCI6MTYyODUwMDk2MiwiZXhwIjoxNjMxMDkyOTYyfQ.b9KxLL21OMsyk8ScGtc4VmMD-UN91Ezo14V_X-w3fnY'
export const TOKEN = PETRO_TOKEN;
